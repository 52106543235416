ion-content ion-toolbar {
  --background: translucent;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: var(--ion-color-medium);
  margin: 0;
}

.container a {
  text-decoration: none;
}

.info {
  margin: 32px 0;
}

.form {
  display: block;
  width: 300px;
  margin: 16px auto;
}

.error {
  margin: 32px 0;
}

ion-button {
  margin-top: 32px;
}
